import { graphql } from "gatsby";
import Image from 'gatsby-image';
import React from "react";
import FooterComponent from '../components/footer/footer';
import { Spacer } from '../components/grid/grid.component';
import { H2, H5 } from '../components/grid/typography.component';
// import Header from '../components/header/header';
import Modules from "../components/modules/modules";
import { HeroImage, HeroText, ImageHeroWrapper } from '../styles/shared';

class ProductTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const product = data.product.data;
    return (
      <div>
        <ImageHeroWrapper className="image-hero-wrapper">
          {/* <div className="clipper">
            <Header color="#fff" />
          </div> */}
          <HeroText>
            <H5>Case study</H5>
            <H2>{product.title.text}</H2>
            <H5>by {product.by.document.map(company => (<span key={company.data.name}>{company.data.name}</span>))}</H5>
          </HeroText>
          <HeroImage>
            <Image fluid={product.image.localFile.childImageSharp.fluid} />
          </HeroImage>
        </ImageHeroWrapper>
        {product.body.map((data, i) =>
          <Modules key={`product-module-${i}`} __typename={data.__typename} data={data} alldata={product} />
        )}
        <Spacer />

        <FooterComponent />
      </div>
    )
  }
}
export default ProductTemplate;

export const query = graphql`
  query($slug: String!, $lang: String!) {
    product: prismicCases(uid: { eq: $slug },lang: { eq: $lang }) {
      id
      data{
        title {
          text
        }
        by {
          document{
              data{
                name
              }
          }
        }
        image {
          ...prismicCasesDataImageFragment
        }
        body{
          __typename
            ... on PrismicCasesBodyWatercolor{
          
              id
              primary{
                watercolor_image {
                  url
                }
              }
            }
            ... on PrismicCasesBodyTextTwoColumns{
              id
              items{
                text {
                  html
                  text
                }
              }
              primary{
                mobile_stack
              }
            }
            ... on PrismicCasesBodyMedia{
              id
              
              items{
                image {
                  ...prismicCasesBodyMediaItemsImageFragment
                }
                caption {
                  html
                  text
                }
              }
            }
            ... on PrismicCasesBodySpacer{
              id
              
            }
        }
      }
    }
  }
`
